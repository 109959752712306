import React, { Component } from "react";
import {
    Modal,
    Box,
    Grid,
    Typography,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { API_HOST, INF_API_HOST, PLATFORM_NAME } from "../../../config/main.json";
import axios from "axios";
import utils from "../../../utils/utils";
import enums from "../../../utils/enums";
import { getAllLists, getAllTasks } from "../../../services/list/ListDetails";
import { snapshotHeaderIcons, textColor } from "../../campaignAnalysis/snapshot";
import StoriesDynamicForm from "../../general/StoriesDynamicForm";
import ArticleIcon from "@mui/icons-material/Article";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import PollIcon from "@mui/icons-material/Poll";
import MicIcon from "@mui/icons-material/Mic";
import { getNotificationType } from "../../../services/account/notification";

class AddPostModal extends Component {
    constructor(props) {
        super(props);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let campId = parseInt(params.get("id"), 10);

        if (isNaN(campId)) {
            console.error("Invalid campaign ID in URL parameters.");
            campId = null;
        }

        this.initialFormState = {
            listSelected_id: null,
            influencerSelected: "",
            postTypeSelected: "",
            postId: "",
            postUrl: "",
            storyData: [],
            postLang: "English",
            clearStoriesDataForm: false,
            platformName: "",
            platformSelected: null,
            label: "Enter the list of Post URLs separated by commas or in new lines",
            placeholder: "Enter the list of Post URLs separated by commas or in new lines",
            influencers: [],
            postTypes: [],
            influencerOptionalFlag: true,
            message: '',
            severity: 'success',
            notification: false,
        };

        this.state = {
            backdropShow: false,
            campaignSelected: campId,
            campaignName: "",
            tasks: [],
            lists: [],
            label: "Enter the list of Post URLs separated by commas or in new lines",
            placeholder: "Enter the list of Post URLs separated by commas or in new lines",
            listSelected_url: "",
            postUrl: "",
            warning: true,
            severity: "success",
            listSelected_id: null,
            influencers: [],
            influencerSelected: "",
            postTypeSelected: "",
            influencerOptionalFlag: true,
            platforms: [],
            platformSelected: null,
            postId: "",
            postTypes: [],
            storyData: [],
            notificationType: [],
            clearStoriesDataForm: false,
            platformName: "",
            message: "",
            notification: false,
            refreshAfterNotification: false,
            warnings: null,
            langList: { all: [] },
            postLang: "English",
            taskSelected_id: null,
            ...this.initialFormState,

        };


    }
    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.resetForm();
            this.getTasks();
            this.getLists();
        }
    }

    resetForm = () => {
        this.setState({ ...this.initialFormState });
    };

    closeNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false, refreshAfterNotification: false });
        if (this.state.severity === 'success' && this.props.onSuccess) {
            this.props.onSuccess();
        }
        this.props.handleClose();
    };

    getTranslatorInfo = () => {
        axios.get(API_HOST + "/translator/info").then((res) => {
            this.setState({ langList: res.data.body?.listing });
        });
    };

    getNotificationType = async () => {
        const res = await getNotificationType();
        this.setState({ notificationType: res });
    };


    getTasks = async () => {
        const campaignId = this.state.campaignSelected;
        const data = await getAllTasks(campaignId.toString());
        this.setState({
            tasks: data,
            listSelected: "",
            influencers: [],
            influencerSelected: "",
            postTypeSelected: "",
            postTypes: [],
            backdropShow: false,
        });
    };

    getLists = async () => {
        const campaignId = this.state.campaignSelected;
        const data = await getAllLists(campaignId.toString(), "all");
        this.setState({
            lists: data,
            listSelected: "",
            influencers: [],
            influencerSelected: "",
            postTypeSelected: "",
            postTypes: [],
            backdropShow: false,
        });
    };

    StoriesDynamicFormCallback = (data) => {
        this.setState({ storyData: data });
    };

    getPlatforms = () => {
        axios.get(API_HOST + "/platform/list").then((res) => {
            const platforms = res.data;
            this.setState({ platforms: platforms });
        });
    };

    getPostTypes = (id) => {
        axios.get(API_HOST + "/postTypes/" + id).then((res) => {
            const postTypes = res.data;
            this.setState({
                postTypes: postTypes,
                postTypeSelected: postTypes[0].id,
            });
        });
    };

    getCampaignInfo = () => {
        const campaignId = this.state.campaignSelected;
        utils.getCampaignData(campaignId).then((data) => {
            this.setState({ campaignName: data.name });
        });
    };

    componentDidMount() {
        this.getTasks();
        this.getLists();
        this.getPlatforms();
        this.getCampaignInfo();
        this.getTranslatorInfo();
        this.getNotificationType();
    }

    submitPostId = async (e) => {
        e.preventDefault();
        const { user } = this.props;
        this.setState({
            backdropShow: true,
            clearStoriesDataForm: !this.state.clearStoriesDataForm,
        });

        const replaceAll = (str, find, replace) => str.replace(new RegExp(find, "g"), replace);
        let postIds = replaceAll(this.state.postId, ",", "\n");
        postIds = replaceAll(postIds, " ", "\n");
        postIds = replaceAll(postIds, "\t", "\n");
        postIds = postIds.split("\n");

        const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=\[\]]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()\[\]@:%_\+.~#?&//=]*)$/;

        postIds = postIds.map((postId) => postId.trim()).filter((postId) => regex.test(postId));

        const images = [];
        this.state.storyData.forEach((story) => {
            if (story.image !== null) {
                images.push(story.image);
            }
        });
        const imageFormData = new FormData();
        let resp;
        if (images.length > 0) {
            images.forEach((image) => {
                imageFormData.append("attachment", image);
            });
            imageFormData.append("cid", this.state.campaignSelected);
            imageFormData.append("lid", this.state.listSelected_id);
            imageFormData.append("iid", this.state.influencerSelected);

            resp = await axios.post(API_HOST + "/post/story/upload", imageFormData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
        }

        let i = 0;
        const storyData = this.state.storyData.map((story) => {
            if (story.image === null) {
                story.image = "";
            } else {
                story.image = resp.data.Url[i++];
            }
            return story;
        });

        const campId = this.state.campaignSelected;

        const postData = {
            cid: this.state.campaignSelected,
            lid: this.state.listSelected_id,
            iid: this.state.influencerSelected === "" ? 0 : parseInt(this.state.influencerSelected, 10),
            platform: this.state.platformSelected,
            postType:
                parseInt(this.state.postTypeSelected, 10) > 3 ? 1 : parseInt(this.state.postTypeSelected, 10),
            postIds: postIds,
            postLang: this.state.postLang,
            tid: this.state.taskSelected_id || 0, // Ensure tid is an integer
            storyData: storyData,
            notificationMetadata: {
                userId: JSON.parse(user.attributes.identities)[0].userId,
                sourcePlatform: PLATFORM_NAME,
                notificationTypeId: "4",
                notificationActionId: "2",
                notificationCategoryId: "1",
                generatedBy: user.attributes.name,
                priority: "high",
                consumedBy: "oryx, empowr",
                navigateTo: "/campaigns/postAnalysis?id=" + campId,
            },
        };

        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        let msg = "Error. Please try again!";
        let severity = "error";
        try {
            const res = await axios.post(API_HOST + "/post/add", postData, optionAxios);
            if (res.status === 200 && res.data.status === "success") {
                this.setState({ backdropShow: false });
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.props.handleClose();
                return;
            } else {
                msg = res.data.status;
                severity = "error";
            }
        } catch (error) {
            console.log("Some error occurred: ", error);
            msg = "An error occurred. Please try again.";
            severity = "error";
        } finally {
            this.setState({
                influencerSelected: "",
                postId: "",
                message: msg,
                severity,
                notification: severity === 'error',
                refreshAfterNotification: severity === 'error',
                backdropShow: false,
            });
        }

    };

    handlListSelection_url = (field, e) => {
        const listSelected_url = e.target.value;
        this.setState({ listSelected_url });
    };

    handlListSelection_id = (field, e) => {
        let lid_platform = e.target.value;
        const data = lid_platform.split(" - ");
        let platformName;
        let taskSelected_id = null;
        const parsedTaskId = parseInt(data[1], 10);

        if (isNaN(parsedTaskId)) {
            platformName = data[1];
        } else {
            taskSelected_id = parsedTaskId;
            platformName = data[2];
        }

        const listSelected_id = parseInt(data[0], 10);

        this.setState({
            listSelected_id,
            taskSelected_id,
            influencerSelected: "",
            postTypeSelected: "",
            platformName,
        });

        this.state.platforms.forEach((values) => {
            if (values["name"] === platformName) {
                const platformSelected = values["id"];
                this.setState({
                    label: "Enter the list of Post URLs separated by commas or in new lines",
                    placeholder: "Example: " + enums.postlinkExample[platformSelected],
                });
                this.setState({ platformSelected });
                this.getPostTypes(values["id"]);
            }
        });
        var organicIdsArray = [];
        var directIdsArray = [];
        axios.get(API_HOST + "/list/influencers/id/" + listSelected_id).then((res) => {
            if (res.data == null || res.data.length === 0) {
                let influencers = [];
                this.setState({ influencers });
                return;
            }

            res.data.forEach((influencer) => {
                if (influencer["type"] === "1") {
                    directIdsArray.push(parseInt(influencer["iid"], 10));
                } else {
                    organicIdsArray.push(parseInt(influencer["iid"], 10));
                }
            });
            const optionAxios = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };

            axios
                .post(
                    `${INF_API_HOST}/influencer/specific/list`,
                    {
                        organicIds: organicIdsArray.join(","),
                        directIds: directIdsArray.join(","),
                        platform: platformName,
                    },
                    optionAxios
                )
                .then((res) => {
                    let influencers = res["data"];
                    if (influencers == null) {
                        influencers = [];
                    }
                    this.setState({ influencers });
                })
                .catch((err) => {
                    console.log("error: ", err);
                });
        });
    };

    handleInfluencerSelection = (field, e) => {
        const influencerSelected = e.target.value;
        this.setState({ influencerSelected });
    };

    handleInPostTypeSelection = (field, e) => {
        const postTypeSelected = e.target.value;
        this.setState({ postTypeSelected });
        // Influencer mandatory for stories and reels
        if (postTypeSelected !== "1" && postTypeSelected !== "2") {
            this.setState({
                influencerOptionalFlag: false,
            });
        } else {
            this.setState({
                influencerOptionalFlag: true,
            });
        }
    };

    handlePostURLChange = (field, e) => {
        const postUrl = e.target.value;
        this.setState({ postUrl });
    };

    handlePostIdChange = (field, e) => {
        const postId = e.target.value;
        this.setState({ postId });
    };
    handleModalClose = (event, reason) => {
        if (reason && reason !== 'backdropClick') {
            this.props.handleClose(event, reason);
        }
    };

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.handleModalClose}
                aria-labelledby="add-post-modal"
                aria-describedby="add-post-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "70vw",
                        maxHeight: "90vh",
                        overflowY: "auto",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                    }}
                >
                    <Snackbar
                        open={this.state.notification}
                        autoHideDuration={3000}
                        onClose={this.closeNotification}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert onClose={this.closeNotification} severity={this.state.severity} sx={{ width: "100%" }}>
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            flexDirection: "column",
                        }}
                        open={this.state.backdropShow}
                    >
                        <CircularProgress color="inherit" />
                        <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
                    </Backdrop>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                        }}
                    >
                        <Typography variant="h5" component="h2">
                            Adding List of Posts
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={this.props.handleClose}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <form onSubmit={this.submitPostId}>
                        <Grid container spacing={2}>
                            {this.state.tasks?.length > 0 ? (
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="task-select-label">Task</InputLabel>
                                        <Select
                                            required={true}
                                            variant="outlined"
                                            label="Task"
                                            id="select-task"
                                            labelId="task-select-label"
                                            value={
                                                this.state.listSelected_id +
                                                " - " +
                                                this.state.taskSelected_id +
                                                " - " +
                                                this.state.platformName
                                            }
                                            onChange={this.handlListSelection_id.bind(this, "listSelected_id")}
                                        >
                                            {this.state.tasks?.map((task) => (
                                                <MenuItem
                                                    key={task.lid + " - " + task.id + " - " + task.platform}
                                                    value={task.lid + " - " + task.id + " - " + task.platform}
                                                >
                                                    <Typography sx={textColor[task.platform]}>
                                                        {snapshotHeaderIcons[task.platform]}
                                                        &nbsp;&nbsp;
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                fontFamily: "Roboto",
                                                                fontStyle: "normal",
                                                                fontWeight: 500,
                                                                lineHeight: "14px",
                                                            }}
                                                        >
                                                            {task.title}
                                                        </span>
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="list-select-label">List</InputLabel>
                                        <Select
                                            required={true}
                                            variant="outlined"
                                            label="List"
                                            id="select-list"
                                            labelId="list-select-label"
                                            onChange={this.handlListSelection_id.bind(this, "listSelected_id")}
                                        >
                                            {this.state.lists.map((list) => (
                                                <MenuItem key={list.id + " - " + list.platform} value={list.id + " - " + list.platform}>
                                                    <Typography sx={textColor[list.platform]}>
                                                        {snapshotHeaderIcons[list.platform]}
                                                        &nbsp;&nbsp;
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                fontFamily: "Roboto",
                                                                fontStyle: "normal",
                                                                fontWeight: 500,
                                                                lineHeight: "14px",
                                                            }}
                                                        >
                                                            {list.name}
                                                        </span>
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="influencer-select-label">
                                        {this.state.influencerOptionalFlag ? "Influencer (Optional)" : "Influencer"}
                                    </InputLabel>
                                    <Select
                                        required={!this.state.influencerOptionalFlag}
                                        variant="outlined"
                                        label={this.state.influencerOptionalFlag ? "Influencer (Optional)" : "Influencer"}
                                        id="select-influencer"
                                        labelId="influencer-select-label"
                                        onChange={this.handleInfluencerSelection.bind(this, "listSelected_id")}
                                        value={this.state.influencerSelected}
                                    >
                                        {this.state.influencers.map((influencer) => (
                                            <MenuItem key={influencer.influencer_id} value={influencer.influencer_id}>
                                                {influencer.name
                                                    ? influencer.name
                                                    : influencer.first_name + " " + influencer.last_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                {this.state.postTypeSelected === "3" ? (
                                    <StoriesDynamicForm
                                        parentCallback={this.StoriesDynamicFormCallback}
                                        clearForm={this.state.clearStoriesDataForm}
                                    />
                                ) : (
                                    <TextField
                                        value={this.state.postId}
                                        onChange={this.handlePostIdChange.bind(this, "postId")}
                                        multiline
                                        variant="outlined"
                                        placeholder={this.state.placeholder}
                                        label={this.state.label}
                                        rows={5}
                                        sx={{ width: "100%" }}
                                        required={true}
                                        helperText="* Provide comma-separated POST URLs for the Influencer you just selected."
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel id="post-type-group-label">Post(s) Type</FormLabel>
                                <FormControl component="fieldset" fullWidth>
                                    <RadioGroup
                                        row
                                        aria-labelledby="post-type-group-label"
                                        name="post-type-group"
                                        value={this.state.postTypeSelected}
                                        onChange={this.handleInPostTypeSelection.bind(this, "listSelected_id")}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label={
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    Post
                                                    <ArticleIcon color="primary" sx={{ ml: 1 }} />
                                                </Box>
                                            }
                                        />
                                        {this.state.platformSelected === 2 && (
                                            <>
                                                <FormControlLabel
                                                    value="9"
                                                    control={<Radio />}
                                                    label={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            Poll
                                                            <PollIcon color="primary" sx={{ ml: 1 }} />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    value="10"
                                                    control={<Radio />}
                                                    label={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            Space
                                                            <MicIcon color="primary" sx={{ ml: 1 }} />
                                                        </Box>
                                                    }
                                                />
                                            </>
                                        )}
                                        {(this.state.platformSelected === 1 || this.state.platformSelected === 6) && (
                                            <>
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio />}
                                                    label={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            Reel
                                                            <VideocamIcon color="primary" sx={{ ml: 1 }} />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    value="3"
                                                    control={<Radio />}
                                                    label={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            Story
                                                            <HistoryToggleOffIcon color="primary" sx={{ ml: 1 }} />
                                                        </Box>
                                                    }
                                                />
                                            </>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="post-lang-select-label">Post(s) Language</InputLabel>
                                    <Select
                                        onChange={(event) => this.setState({ postLang: event.target.value })}
                                        variant="outlined"
                                        labelId="post-lang-select-label"
                                        label="Post(s) Language"
                                        value={this.state.postLang}
                                    >
                                        {this.state.langList.all.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                {lang}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "right" }}>
                                <Box sx={{ mt: 2 }}>
                                    <Button variant="contained" size="large" type="submit">
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const user = state.user;
    return { user };
};
export default connect(mapStateToProps)(AddPostModal);
