import React, { Component } from "react";
import utils from "../../../utils/utils";
import FetchingDetailsLoader from "../../general/FetchingDetailsLoader";
import { API_HOST, SLACK_EP, GOOGLE_DRIVE_EP, ENHANCE_API_HOST } from "../../../config/main.json";
import { Box, Button, Divider, Grid, TextField, Typography, CircularProgress, Backdrop, Snackbar } from "@mui/material";
import MyPaper from "../../general/MyPaper";
import { BadgeTwoTone, Close } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import FormDateRangeCustomPicker from "../../../utils/formdaterangepicker";
import axios from "axios";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import AddBrand from '../../../components/brand/details/AddBrand';
import currencyData from "../../../utils/currencyData";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// below function allows the Alert component to receive a ref and pass it down to the underlying MuiAlert component.
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


let campId = 0;
let backdropShow = false;
let isSlack = false;
let isGdrive = false

class CampaignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            aifluencerUsers: [],
            fields: {}, // used to save all the details/fields of a campaign that the user enters while creating a campaign
            loading: false,
            SaveButtonDisable: true,
            disableError: "All fields marked with * are mandatory",
            brandList: [],
            userId: {},
            timezoneData: [], // get timezones filtered by id(all)
            notification: false,
            message: "",
            readOnly: !props.isEdit,
            currencyName: "",
            fieldsChanged: {},// helps to check that a field is changed or not and decides the content of save modal
            fieldsFilled: {},// helps to check that a field is filled or not and decides the content of save modal
            backdropShow: backdropShow,
            isSlack: isSlack,
            isGdrive: isGdrive,
            nameCharacterCount: 0, // count the Character of campaigns name
            nameSaveButtonDisable: false, // disable/ enable the save button , depends on campaign name length
            nameDraftButtonDisable: false, // disable/ enable the save as draft button , depends on campaign name length
            descriptionCharacterCount: 0, // count the Character of campaigns object/ desc
            descSaveButtonDisable: false, // disable/ enable the save button , depends on campaign object length
            descDraftButtonDisable: false, // disable/ enable the save as draft button , depends on campaign object length
            timezone: {},
            brandError: "", // Custom error message to create a brand for the account
            campaignType: ["Create brand awareness", "Make more sales", "Generate Leads", "Generate traffic to website/App Downloads"],
            timezoneAutocomplete: false,
            Currencys: currencyData,
            keywords: [],
            inputValue: "",
            cancleModal: false, // controlls the cancle modal
            saveModal: false, // controlls the save modal
            addBrandModal: false,// controlls the addBrand Modal
            allowBrandCreation: true,
            disableEnhance: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTimezone = this.handleChangeTimezone.bind(this);
        this.handleChangeAccount = this.handleChangeAccount.bind(this);
        this.editCampaign = this.editCampaign.bind(this);
        this.saveTheAddedBrand = this.saveTheAddedBrand.bind(this);
        this.enhanceObjective = this.enhanceObjective.bind(this);

        this.optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
    }

    getAiFluenceUsers() {
        const data = { type: 1, page: 0, rows: 1000, sortCol: "createdAt", sortOrder: "desc", search: "" };
        utils.getAiFluenceUsers(data).then((data) => {
            const aifluencerUsers = data.users.filter((user) => user.role === "manager");
            this.setState({ aifluencerUsers });
        });
    }

    async getCampaignDetails(campId) {
        var loading = true;
        this.setState({ loading });
        utils.getCampaignData(campId).then((data) => {
            const fields = data;
            this.getAiFluenceUsers();
            this.setState({ SaveButtonDisable: false, disableError: "" });
            this.setState({ fields });
            this.getBrands(this.state.fields["companyId"]);
            fields["accountManager"] = { id: fields["accountManagerId"], name: fields["accountManagerName"] };
            if (this.state.fields["startDate"] !== "") fields["startDate"] = this.state.fields["startDate"].split("T")[0];
            if (this.state.fields["endDate"] !== "") fields["endDate"] = this.state.fields["endDate"].split("T")[0];
            if (fields["timezone"] === -1) {
                this.setState({
                    timezoneAutocomplete: false,
                });
            } else {
                this.setState({
                    timezoneAutocomplete: true,
                    timezone: this.state.timezoneData[+fields["timezone"]],
                });
            }

            //this.setState({keywords : this.state.dummyKeywords.split(", ")})
            this.setState({ keywords: fields["keywords"].length > 0 ? fields["keywords"].split(", ") : [] });

            this.getCompanyName(this.state.fields["companyId"]);

            if (this.state.fields["currency"]) {
                const filteredCurrency = this.state.Currencys.filter(currency => currency.value === this.state.fields["currency"]);
                this.setState({ currencyname: filteredCurrency[0].name })
            }

            this.setState({ fields, loading: false });
        });
    }

    getCompanyName(companyId) {
        this.setState({ loading: true });
        utils.getCompanyData().then((data) => {
            const companyList = data;
            this.setState({ companyList });
        });
    }

    getCompanies() {
        utils.getCompanyData().then((data) => {
            const companyList = data;
            this.setState({ companyList });
        });
    }

    getBrands(accId) {
        if (accId === undefined) {
            this.setState({ allowBrandCreation: false });
            var brandList = [];
            var newBrand = {};
            newBrand["id"] = "";
            newBrand["name"] = "Please select an account first.";
            brandList = [];
            brandList.push(newBrand);
            this.setState({ brandList });
        } else {
            this.setState({ allowBrandCreation: true });
            utils.getBrandData(accId).then((data) => {
                var brandList = data;
                if (brandList == null) {
                    var newBrand = {};
                    newBrand["id"] = 0;
                    newBrand["name"] = "Please create a brand within the selected account.";
                    brandList = [];
                    brandList.push(newBrand);
                }
                this.setState({ brandList });
            });
        }
    }


    getTimezoneData() {
        utils.getTimezoneData("all").then((data) => {
            const timezoneData = data;
            this.setState({ timezoneData });
        });
    }

    componentDidMount() {
        if (this.props.type === "AddCampaign") {
            this.getAiFluenceUsers();
            this.setState({ fields: { duration: "custom", startDate: this.getCurrentDate() } });
        }
        if (this.props.type === "EditCampaign") {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            campId = params.get("id");
            this.getCampaignDetails(campId);
        }

        this.getCompanies();
        this.getBrands();
        this.getTimezoneData();
    }

    getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Add leading zeros if month or day is less than 10
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }

        return `${year}-${month}-${day}`;
    }

    componentDidUpdate(prevProps, prevState) {
        const { fields } = this.state;
        fields["endDate"] = utils.convertDate(fields["endDate"], "normal")
        if (prevProps.isEdit !== this.props.isEdit) {
            this.setState({ readOnly: !this.props.isEdit });
        }

        if (
            fields["name"] &&
            fields["brandId"] &&
            fields["description"] &&
            fields["currency"] &&
            fields["budget"] &&
            fields["startDate"] &&
            (fields["endDate"] && fields["endDate"] !== "Invalid date") &&
            fields["campaignType"] &&
            (fields["companyId"] || fields["companyId"] === 0)
        ) {
            if (this.state.SaveButtonDisable) {
                this.setState({ SaveButtonDisable: false, disableError: "" });
            }
        } else {
            if (!this.state.SaveButtonDisable) {
                this.setState({ SaveButtonDisable: true, disableError: "All fields marked with * are mandatory" });
            }
        }
    }

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default Enter key behavior within the form
        }
    };

    handleChange(field, e, values) {
        this.setState({ [field]: e.target.value });
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;
        const val = values ? values : e.target.value;
        if (field === "name") {
            this.setState({ nameCharacterCount: val.length });
            if (val.length > 40) {
                if (!this.state.nameSaveButtonDisable && !this.state.nameDraftButtonDisable) {
                    this.setState({ nameSaveButtonDisable: true });
                    this.setState({ nameDraftButtonDisable: true });
                }
            } else {
                if (this.state.nameSaveButtonDisable && this.state.nameDraftButtonDisable) {
                    this.setState({ nameSaveButtonDisable: false });
                    this.setState({ nameDraftButtonDisable: false });
                }
            }
        }
        if (field === "description") {
            this.setState({ descriptionCharacterCount: val.length });
            if (val.length > 140) {
                if (!this.state.descSaveButtonDisable && !this.state.descDraftButtonDisable) {
                    this.setState({ descSaveButtonDisable: true });
                    this.setState({ descDraftButtonDisable: true });
                }
            } else {
                if (this.state.descSaveButtonDisable && this.state.descDraftButtonDisable) {
                    this.setState({ descSaveButtonDisable: false });
                    this.setState({ descDraftButtonDisable: false });
                }
            }
        }
        if (field === "currency") {
            if (val === undefined) {
                fields[field] = "";
                this.setState({ currencyname: "" })
            } else {
                fields[field] = val.value;
                this.setState({ currencyname: val.name })
            }
        } else {
            fields[field] = val;
        }

        fieldsChanged[field] = true;
        fieldsFilled[field] = true;

        this.setState({ fields });
        this.setState({ fieldsChanged });
        this.setState({ fieldsFilled });
    }

    enhanceObjective() {
        let fields = this.state.fields;
        this.setState({ disableEnhance: true })
        let text_value = this.state.fields["description"]

        const data = {
            action: "enhance_text",
            text_value: text_value,
            character_length: 140,
        };

        axios.post(ENHANCE_API_HOST + "/enhanceText", data, this.optionAxios)
            .then((res) => {
                let val = res.data.response
                this.setState({ descriptionCharacterCount: val.length });
                if (val.length > 140) {
                    if (!this.state.descSaveButtonDisable && !this.state.descDraftButtonDisable) {
                        this.setState({ descSaveButtonDisable: true });
                        this.setState({ descDraftButtonDisable: true });
                    }
                } else {
                    if (this.state.descSaveButtonDisable && this.state.descDraftButtonDisable) {
                        this.setState({ descSaveButtonDisable: false });
                        this.setState({ descDraftButtonDisable: false });
                    }
                }
                fields["description"] = val;
                this.setState({ disableEnhance: false })
                this.setState({ fields });
            })
            .catch((err) => {
                console.error("Error:", err);
            });
    }

    handleChangeAccount(values, field) {
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;
        fields[field] = "";
        fields["companyName"] = "";
        if (values !== null) {
            if (values.length > 0) {
                var data = [];
                values.forEach(function (item, index) {
                    data.push(item["id"]);
                });
                fields[field] = data.join(",");
            } else if (typeof values["id"] !== "undefined") {
                fields[field] = values["id"];
            }
            fields["companyName"] = values.name;
            this.getBrands(values.id);
        } else {
            this.getBrands();
        }
        fieldsFilled[field] = true;
        fieldsChanged[field] = true;
        this.setState({ fieldsFilled });
        this.setState({ fieldsChanged });
        this.handleChangeBrand("", "brandId");
    }

    handleChangeBrand(values, field) {
        if (values !== null && values.name == "+ Create a Brand") {
            this.setState({ addBrandModal: true });
            return;
        }
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;
        fields[field] = "";
        fields["brandName"] = "";
        if (values !== null) {
            if (values.length > 0) {
                var data = [];
                values.forEach(function (item, index) {
                    data.push(item["id"]);
                });
                fields[field] = data.join(",");
            } else if (typeof values["id"] !== "undefined") {
                fields[field] = values["id"];
                fields["brandName"] = values["name"];
            }
        }

        fieldsFilled[field] = true;
        fieldsChanged[field] = true;
        this.setState({ fields });
        this.setState({ fieldsFilled });
        this.setState({ fieldsChanged });
    }

    handleChangeTimezone(e, value) {
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;
        fields["timezone"] = "";
        fields["timezoneText"] = "";
        if (value !== null) {
            if (value.length > 0) {
                var data = [];
                value.forEach(function (item, index) {
                    data.push(item["id"]);
                });
                fields["timezone"] = data.join(",");
            } else if (typeof value["id"] !== "undefined") {
                fields["timezone"] = value["id"];

                fields["timezoneText"] = value["text"];
            }
        }
        this.setState({ fields, timezone: value });
        fieldsChanged["timezone"] = true;
        fieldsFilled["timezone"] = true;
        this.setState({ fields });
        this.setState({ fieldsChanged });
    }

    sendSelectedDates = ({ startDate, endDate }) => {
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;

        fields["startDate"] = startDate;
        fields["endDate"] = endDate;
        fieldsFilled["endDate"] = true;

        if (this.props.type === "EditCampaign") {
            fieldsChanged["startDate"] = true;
            fieldsChanged["endDate"] = true;
            this.setState({ fieldsChanged });
        }

        this.setState({ fields });
        this.setState({ fieldsFilled });
    };

    handleAccountManagerChange = (value, field) => {
        let fields = this.state.fields;
        fields[field] = value;
        this.setState({ fields });
    };

    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };

    handleInputKeyDown = (event) => {
        if (event.key === "Enter") {
            this.handleInput();
        }
    };

    handleInputBlur = () => {
        this.handleInput();
    };

    handleInput = () => {
        const newKeyword = this.state.inputValue.trim();
        if (newKeyword) {
            this.setState(
                (prevState) => ({
                    keywords: [...prevState.keywords, newKeyword],
                    inputValue: "",
                }),
                () => {
                    // Call handleKeywordsChange after the state has been updated
                    this.handleKeywordsChange();
                }
            );
        }
    };

    handleChipDelete = (chipToDelete) => () => {
        this.setState(
            (prevState) => ({
                keywords: prevState.keywords.filter((chip) => chip !== chipToDelete),
            }),
            () => {
                // Call handleKeywordsChange after the state has been updated
                this.handleKeywordsChange();
            }
        );
    };

    handleKeywordsChange = () => {
        let field = "keywords";
        let fields = this.state.fields;
        let fieldsFilled = this.state.fieldsFilled;
        let fieldsChanged = this.state.fieldsChanged;
        fieldsChanged[field] = true;
        const val = this.state.keywords;
        fields[field] = val;
        fieldsFilled[field] = true;
        this.setState({ fields });
        this.setState({ fieldsFilled });
        this.setState({ fieldsChanged });
    };

    getTooltipTitle = () => {
        const { fields } = this.state;
        fields["endDate"] = utils.convertDate(fields["endDate"], "normal");
        const string = [];

        if (!fields["name"]) {
            string.push("Campaign Name is empty");
        }
        if (!fields["brandId"]) {
            string.push("Brand Name is empty");
        }
        if (!fields["description"]) {
            string.push("Objective is empty");
        }
        if (!fields["currency"]) {
            string.push("Currency is empty");
        }
        if (!fields["budget"]) {
            string.push("Budget is empty");
        }
        if (!fields["startDate"]) {
            string.push("Start Date is empty");
        }
        if (!fields["endDate"] || fields["endDate"] === "Invalid date") {
            string.push("End Date is empty");
        }
        if (!fields["campaignType"]) {
            string.push("Campaign Type is empty");
        }
        if (!fields["companyId"] && fields["companyId"] !== 0) {
            string.push("Account Name is empty");
        }

        if (string.length > 0) {
            return (
                <div>
                    {string.map((str, index) => (
                        <div key={index}>{str}</div>
                    ))}
                </div>
            );
        }
        else {
            return ""
        }
    }

    postCampaignCancel() {
        const cancleModal = true;
        this.setState({ cancleModal });
    }

    postCampaignDraftSave() {
        if (!this.state.fields["name"] || (!this.state.fields["companyId"] && this.state.fields["companyId"] !== 0)) {
            const notification = true;
            const message = "Campaign Name and Account Name are mandatory!";
            this.setState({ notification, message });
            return;
        }

        if (this.props.type === "AddCampaign") {
            const saveModal = true;
            this.setState({ saveModal });
            this.setState({ type: "draftSave" })
        }
        if (this.props.type === "EditCampaign") {
            let changesDone = false;

            for (const key in this.state.fieldsChanged) {
                if (this.state.fieldsChanged[key]) {
                    changesDone = true;
                    break;
                }
            }

            if (changesDone) {
                const saveModal = true;
                this.setState({ saveModal });
                this.setState({ type: "draftSave" })
            } else {
                this.editDraftCampaignt()
            }
        }
    }

    postCampaignSave() {
        if (this.props.type === "AddCampaign") {
            const saveModal = true;
            this.setState({ saveModal });
            this.setState({ type: "save" });
        }
        if (this.props.type === "EditCampaign") {
            let changesDone = false;

            for (const key in this.state.fieldsChanged) {
                if (this.state.fieldsChanged[key]) {
                    changesDone = true;
                    break;
                }
            }

            if (changesDone) {
                const saveModal = true;
                this.setState({ saveModal });
                this.setState({ type: "save" })
            } else {
                this.editCampaign();
            }
        }
    }

    async assignAccountManager(accountManager, companyId) {
        const data = {
            userId: accountManager.id,
            aids: [companyId],
        };
        const response = await utils.assignAccountManager(data);
    }

    editDraftCampaignt() {
        const saveModal = false;
        this.setState({ saveModal });
        backdropShow = true;
        this.setState({ backdropShow });
        let body = { ...this.state.fields };

        delete body["brandName"];
        delete body["companyName"];
        delete body["attributes"];
        delete body["primaryCat"];
        delete body["secondaryCat"];
        delete body["businessType"];
        delete body["attributeNames"];
        delete body["platforms"];
        delete body["listStatus"];
        delete body["timezoneText"];
        if (body["budget"] === "") delete body["budget"];
        if (body["brandId"] === -1) delete body["brandId"];
        if (body["budgetType"] === "") delete body["budgetType"];
        if (body["campaignType"] === "") delete body["campaignType"];
        if (body["currency"] === "") delete body["currency"];
        if (body["description"] === "") delete body["description"];
        if (body["duration"] === "") delete body["duration"];
        if (body["endDate"] === "") delete body["endDate"];
        if (body["gdriveId"] === "") delete body["gdriveId"];
        if (body["noOfDuration"] === -1) delete body["noOfDuration"];
        if (body["slackId"] === "") delete body["slackId"];
        if (body["startDate"] === "") delete body["startDate"];
        if (body["timezone"] === -1) delete body["timezone"];

        if (body["startDate"] !== undefined) {
            body["startDate"] = utils.convertDate(body["startDate"], "normal");
        }

        if (body["endDate"] !== undefined) {
            body["endDate"] = utils.convertDate(body["endDate"], "normal");
        }

        body["cid"] = parseInt(campId);
        if (body["duration"] !== undefined && body["startDate"] !== undefined) {
            if (body["duration"] !== "custom") {
                if (body["noOfDuration"] !== undefined) {
                    var duration = Number(body["noOfDuration"]);
                    body["noOfDuration"] = duration;
                    if (body["duration"] === "days") {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "days");
                    } else if (body["duration"] === "weeks") {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "weeks");
                    } else {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "months");
                    }
                }
            }
        }
        if (this.state.fields.accountManager) {
            this.assignAccountManager(this.state.fields.accountManager, this.state.fields["companyId"]);
        }
        body["keywords"] = typeof body["keywords"] !== "string" && body["keywords"]?.join(", ");


        axios.post(`${API_HOST}/campaign/edit`, body, this.optionAxios).then((res) => {
            setTimeout(function () {
                window.location = "/campaigns";
            }, 1000);
        });
    }

    saveTheAddedBrand = () => {
        this.setState({ addBrandModal: false });
        this.getBrands(this.state.fields["companyId"]);
    }

    editCampaign(e) {
        const saveModal = false;
        this.setState({ saveModal });
        backdropShow = true;
        this.setState({ backdropShow });
        if (true) {
            let body = { ...this.state.fields };
            let sDate = new Date(body["startDate"]);
            let durationType = body["duration"];
            body["keywords"] = typeof body["keywords"] !== "string" && body["keywords"]?.join(", ");
            body["startDate"] = utils.convertDate(body["startDate"], "normal");

            if (!body["timezone"]) {
                body["timezone"] = 36;
                let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                for (let i of this.state.timezoneData) {
                    if (i.utc.split(",").includes(localTimezone)) {
                        body["timezone"] = i.id;
                        break;
                    }
                }
            }
            body["userId"] = 1;
            delete body["brandName"];
            delete body["companyName"];
            delete body["attributes"];
            delete body["primaryCat"];
            delete body["secondaryCat"];
            delete body["businessType"];
            delete body["attributeNames"];
            delete body["platforms"];
            delete body["listStatus"];
            delete body["timezoneText"];
            body["id"] = campId;
            body["cid"] = parseInt(campId);
            if (this.state.fields.accountManager) {
                this.assignAccountManager(this.state.fields.accountManager, this.state.fields["companyId"]);
            }
            if (body["current_status"] === 2) {
                let nd = new Date();
                sDate.setHours(0);
                sDate.setMinutes(0);
                sDate.setSeconds(0);
                sDate.setMilliseconds(0);
                nd.setHours(0);
                nd.setMinutes(0);
                nd.setSeconds(0);
                nd.setMilliseconds(0);
                if (sDate < nd) {
                    const notification = true;
                    const message = "Start Date should be today's or a future date!";
                    this.setState({ notification, message });
                    backdropShow = false;
                    this.setState({ backdropShow });
                    return;
                } else if (sDate.getTime() === nd.getTime()) {
                    body["current_status"] = 1;
                } else if (sDate > nd) {
                    body["current_status"] = 4;
                }

                Promise.all([this.callGdrive(this.state.fields), this.callSlack(this.state.fields)]).then((values) => {
                    body["slackId"] = this.state.fields["slackId"];
                    body["slack"] = this.state.fields["slack"];
                    body["gdriveId"] = this.state.fields["gdriveId"];
                    body["gdrive"] = this.state.fields["gdrive"];
                    axios.post(API_HOST + "/campaign/edit", body, this.optionAxios).then((res) => {
                        setTimeout(function () {
                            window.location = "/campaigns";
                        }, 1000);
                    });
                });
            } else {
                if ([1, 4].includes(body["current_status"])) {
                    let sDate = utils.convertDate(body["startDate"], "normal");
                    let eDate = utils.convertDate(body["endDate"], "normal");
                    let cDate = utils.convertDate(new Date(), "normal");
                    if (cDate < sDate) {
                        body["current_status"] = 4;
                    } else if (sDate <= cDate && cDate <= eDate) {
                        body["current_status"] = 1;
                    } else {
                        body["current_status"] = 5;
                    }
                }

                if ([5].includes(body["current_status"])) {
                    let sDate = utils.convertDate(body["startDate"], "normal");
                    let eDate = utils.convertDate(body["endDate"], "normal");
                    let cDate = utils.convertDate(new Date(), "normal");
                    if (cDate < sDate) {
                        body["current_status"] = 4;
                    } else if (sDate <= cDate && cDate <= eDate) {
                        body["current_status"] = 1;
                    } else {
                        body["current_status"] = 5;
                    }
                }

                axios.post(API_HOST + "/campaign/edit", body, this.optionAxios).then((res) => {
                    setTimeout(function () {
                        window.location = "/campaigns";
                    }, 1000);
                });
            }
        }
    }

    createCampaign(e) {
        // e.preventDefault();
        var fields = this.state.fields;
        const saveModal = false;
        this.setState({ saveModal });
        backdropShow = true;
        this.setState({ backdropShow });
        this.state.companyList.forEach(function (item, index) {
            if (item["id"] === fields["companyId"]) {
                fields["companyName"] = item["name"];
            }
        });
        this.setState({ fields });
        var body = this.state.fields;
        let sDate = new Date(body["startDate"]);
        let nd = new Date();
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        sDate.setMilliseconds(0);
        nd.setHours(0);
        nd.setMinutes(0);
        nd.setSeconds(0);
        nd.setMilliseconds(0);
        if (sDate < nd) {
            const notification = true;
            const message = "Start Date should be today's or a future date!";
            this.setState({ notification, message });
            backdropShow = false;
            this.setState({ backdropShow });
            return;
        } else if (sDate.getTime() === nd.getTime()) {
            body["current_status"] = 1;
        } else if (sDate > nd) {
            body["current_status"] = 4;
        }
        if (this.state.fields.accountManager) {
            this.assignAccountManager(this.state.fields.accountManager, fields["companyId"]);
        }
        // wait for all the extrnal APIs to complete their task
        Promise.all([this.callGdrive(this.state.fields), this.callSlack(this.state.fields)]).then((values) => {
            body["slackId"] = this.state.fields["slackId"];
            body["slack"] = this.state.fields["slack"];
            body["gdriveId"] = this.state.fields["gdriveId"];
            body["gdrive"] = this.state.fields["gdrive"];
            delete body["brandName"];
            delete body["companyName"];
            var durationType = body["duration"];
            body["startDate"] = utils.convertDate(body["startDate"], "normal");
            if (durationType !== "custom") {
                var duration = Number(body["noOfDuration"]);
                body["noOfDuration"] = duration;
                if (durationType === "days") {
                    body["endDate"] = utils.AddDays(body["startDate"], duration, "days");
                } else if (durationType === "weeks") {
                    body["endDate"] = utils.AddDays(body["startDate"], duration, "weeks");
                } else {
                    body["endDate"] = utils.AddDays(body["startDate"], duration, "months");
                }
            } else {
                body["endDate"] = utils.convertDate(body["endDate"], "normal");
            }
            if (!body["timezone"]) {
                body["timezone"] = 36;
                let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                for (let i of this.state.timezoneData) {
                    if (i.utc.split(",").includes(localTimezone)) {
                        body["timezone"] = i.id;
                        break;
                    }
                }
            }
            body["keywords"] = typeof body["keywords"] !== "string" && body["keywords"]?.join(", ");
            body["userId"] = 1;
            body["addedFrom"] = "oryx";
            this.postData(body);
        });
    }

    /* main method to add campaIgn in the DB */
    postData(data) {
        setTimeout(function () {
            axios.post(`${API_HOST}/campaign/add`, data, this.optionAxios).then((res) => {
                window.location = "/campaigns";
            });
        }, 5000);
    }

    async callSlack(fields) {
        fields["slack"] = 0;
        var optionAxios = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        var slackBody = {};
        slackBody["operation"] = "CreateChannel";
        slackBody["channelName"] = fields["name"].replace(/ /g, "-").toLowerCase();
        try {
            await axios.post(SLACK_EP, slackBody, optionAxios).then((res) => {
                fields["slackId"] = res["data"]["channelId"];
                fields["slack"] = 1;
            });
            this.setState({ fields });
            isSlack = true;
            this.setState({ isSlack });
        } catch (error) {
            console.log(error);
        }
    }

    async callGdrive(fields) {
        fields["gdrive"] = 0;
        var optionAxios = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        var gdriveBody = {};
        gdriveBody["operation"] = "CreateFolder";
        gdriveBody["companyName"] = fields["companyName"];
        gdriveBody["campaignName"] = fields["name"];
        gdriveBody["campaignId"] = "1";
        try {
            await axios.post(GOOGLE_DRIVE_EP, gdriveBody, optionAxios).then((res) => {
                fields["gdriveId"] = res["data"]["id"];
                fields["gdrive"] = 1;
            });
            this.setState({ fields });
            isGdrive = true;
            this.setState({ isGdrive });
        } catch (error) {
            console.log(error);
        }
    }

    createDraftCampaign() {
        const saveModal = false;
        this.setState({ saveModal });
        backdropShow = true;
        this.setState({ backdropShow });
        let body = this.state.fields;

        body["userId"] = 1;
        body["current_status"] = 2;
        body["addedFrom"] = "oryx";

        if (body["startDate"] !== undefined) {
            body["startDate"] = utils.convertDate(body["startDate"], "normal");
        }

        if (this.state.fields.accountManager) {
            this.assignAccountManager(this.state.fields.accountManager, this.state.fields["companyId"]);
        }

        if (body["endDate"] !== undefined) {
            body["endDate"] = utils.convertDate(body["endDate"], "normal");
        }

        if (body["duration"] !== undefined && body["startDate"] !== undefined) {
            if (body["duration"] !== "custom") {
                if (body["noOfDuration"] !== undefined) {
                    var duration = Number(body["noOfDuration"]);
                    body["noOfDuration"] = duration;
                    if (body["duration"] === "days") {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "days");
                    } else if (body["duration"] === "weeks") {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "weeks");
                    } else {
                        body["endDate"] = utils.AddDays(body["startDate"], duration, "months");
                    }
                }
            }
        }

        body["keywords"] = typeof body["keywords"] !== "string" && body["keywords"]?.join(", ");
        this.postData(body);
    }


    render() {
        const closeNotification = () => {
            this.setState({ notification: false });
        };

        const closeModal = () => {
            this.setState({ cancleModal: false });
        };

        const cancelCampaign = () => {
            window.location = "/campaigns";
        };

        const closeSaveModal = () => {
            this.setState({ saveModal: false });
            this.setState({ type: "" });
        };

        const saveCampaign = () => {
            if (this.props.type === "AddCampaign") {
                this.createCampaign();
            }
            if (this.props.type === "EditCampaign") {
                this.editCampaign();
            }
        };

        const saveDraftCampaign = () => {
            if (this.props.type === "AddCampaign") {
                this.createDraftCampaign()
            }
            if (this.props.type === "EditCampaign") {
                this.editDraftCampaignt();
            }
        }

        const handleCloseModal = () => {
            this.setState({ addBrandModal: false });
        };

        if (this.state.loading) {
            return <FetchingDetailsLoader />;
        } else {
            return (
                <>
                    {/* Snackbar component is typically used to display notifications or alerts to the user.
      open: This prop controls whether the Snackbar is currently open or closed.  
      this Snackbar apper when we save draft withiut campaig name or company name  */}
                    <Snackbar
                        open={this.state.notification}
                        autoHideDuration={3000}
                        onClose={closeNotification}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert onClose={closeNotification} severity="error" sx={{ width: "100%" }}>
                            {this.state.message}
                        </Alert>
                    </Snackbar>

                    <form onKeyDown={this.handleKeyDown.bind(this)}>
                        {/* The .bind(this) part ensures that this inside the createCampaign function refers to the component instance. */}
                        {/* when backdropShow state is true(on saving the campaign), the Backdrop with a loading indicator and text is displayed to 
                        indicate that data is being submitted */}
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                flexDirection: "column",
                            }}
                            open={this.state.backdropShow}
                        >
                            <CircularProgress color="inherit" />
                            <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
                        </Backdrop>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MyPaper>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                                        Basic Details
                                    </Typography>
                                    <Divider />

                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid item xs={12} sm={6}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Campaign Name *
                                                </Typography>
                                            </div>
                                            <TextField
                                                refs="name"
                                                value={this.state.fields["name"]}
                                                onChange={this.handleChange.bind(this, "name")}
                                                type="text"
                                                sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                variant="outlined"
                                                placeholder="Choose a short name to best describe the campaign."
                                                helperText={
                                                    this.state.nameCharacterCount <= 40 ? (
                                                        <div style={{ marginTop: -5 }}>Campaign Name can have maximum 40 characters</div>
                                                    ) : (
                                                        <div style={{ color: "red", marginTop: -5 }}>You've exceeded the 40 character limit for the campaign name.</div>
                                                    )
                                                }
                                                inputProps={{ readOnly: this.state.readOnly }}
                                                InputLabelProps={{ shrink: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <span style={{ fontSize: 12 }}>{this.state.nameCharacterCount} /40</span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Account Name *
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    readOnly={this.state.readOnly}
                                                    options={this.state.companyList}
                                                    getOptionLabel={(cname) => cname.name || this.state.fields["companyName"]}
                                                    value={this.state.fields["companyName"]}
                                                    onChange={(event, value) => this.handleChangeAccount(value, "companyId")}
                                                    refs="companyId"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                            placeholder="Choose account for campaign"
                                                            InputLabelProps={{ shrink: true }}
                                                            value={String(this.state.fields["companyId"])}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Brand Name*
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    readOnly={this.state.readOnly}
                                                    //options={[{ name: '+ Create a Brand' }, ...this.state.brandList]}
                                                    options={this.state.allowBrandCreation ? [{ name: '+ Create a Brand' }, ...this.state.brandList] : this.state.brandList}
                                                    getOptionLabel={(bname) => bname.name || String(this.state.fields["brandName"] || "")}
                                                    value={this.state.fields.brandName || ""}
                                                    inputValue={this.state.fields.brandName || ""} // Always controlled
                                                    onChange={(event, value) => this.handleChangeBrand(value, "brandId")}
                                                    inputRef={(ref) => (this.brandInputRef = ref)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                            placeholder="Choose brand or create brand for the account"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                    getOptionDisabled={(option) =>
                                                        option.name === "Please select an account first." ||
                                                        option.name === "Please create a brand within the selected account."
                                                    } // Disable the option above options.
                                                />
                                                <span style={{ color: "red" }}>{this.state.brandError}</span>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div style={{ marginBottom: -3, display: "flex" }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    style={{ color: "#000", marginBottom: 0 }}
                                                >
                                                    Objective of the Campaign *
                                                </Typography>
                                                <Tooltip title="Describe campaigns goals and objective. Campaign objectives refer to the goals and targets set for the campaign to achieve desired outcomes. These objectives serve as a roadmap for the campaign, guiding the efforts and strategies implemented to reach a particular audience and accomplish the predefined results.">
                                                    <IconButton>
                                                        <InfoIcon style={{ fontSize: 15 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div style={{ position: "relative", width: "100%" }}>
                                                <TextField
                                                    refs="description"
                                                    value={this.state.fields["description"]}
                                                    onChange={this.handleChange.bind(this, "description")}
                                                    variant="outlined"
                                                    sx={{
                                                        "& legend": { display: "none" },
                                                        "& fieldset": { top: 7, bottom: 7 },
                                                        width: "100%",
                                                    }}
                                                    placeholder="Choose a short objective of the campaign to describe its goals."
                                                    InputLabelProps={{ shrink: true }}
                                                    multiline
                                                    rows={2}
                                                    helperText={
                                                        this.state.descriptionCharacterCount <= 140 ? (
                                                            <div style={{ marginTop: -5 }}>
                                                                Campaign Name can have maximum 140 characters
                                                            </div>
                                                        ) : (
                                                            <div style={{ color: "red", marginTop: -5 }}>
                                                                You've exceeded the character limit for the campaign
                                                                object.
                                                            </div>
                                                        )
                                                    }
                                                    inputProps={{ readOnly: this.state.readOnly }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {this.state.descriptionCharacterCount > 0 ? (
                                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                                                                        <span style={{ fontSize: 12, width: "50px", marginBottom: 0 }}>
                                                                            {this.state.descriptionCharacterCount}/140
                                                                        </span>
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            style={{
                                                                                fontSize: 11,
                                                                                padding: "2px 8px",
                                                                            }}
                                                                            disabled={this.state.disableEnhance || this.state.descriptionCharacterCount > 140}
                                                                            onClick={this.enhanceObjective}
                                                                        >
                                                                            {this.state.disableEnhance ? (
                                                                                "Loading..."
                                                                            ) : (
                                                                                <>
                                                                                    Enhance <AutoAwesomeIcon sx={{ height: "14px" }} />
                                                                                </>
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                ) : (<>
                                                                    <span style={{ fontSize: 12, width: "50px" }}>
                                                                        {this.state.descriptionCharacterCount}/140
                                                                    </span>
                                                                </>)}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </Grid>


                                        <Grid item xs={12} sm={3}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Budget *
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                <TextField
                                                    refs="budget"
                                                    value={this.state.fields["budget"]}
                                                    onChange={this.handleChange.bind(this, "budget")}
                                                    variant="outlined"
                                                    sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                    placeholder="Choose budget ."
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        inputProps: { min: 0, maxLength: 7, readOnly: this.state.readOnly },
                                                        onKeyPress: (event) => {
                                                            // Prevent further input after 7 digits
                                                            if (event?.target.value.length === 7 && event?.key !== "Backspace") {
                                                                event.preventDefault(); // Prevent further input
                                                            }
                                                            // Prevent entering invalid characters and only 0 as input
                                                            if (event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key === "e") {
                                                                event.preventDefault();
                                                            }
                                                            // Check if the input is only 0 or starts with 0
                                                            if (event?.target.value.startsWith("0")) {
                                                                event.preventDefault();
                                                            }
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Currency *
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    readOnly={this.state.readOnly}
                                                    // This component allows users to select one option from a predefined list, similar to a dropdown but with the added feature of allowing free text entry.
                                                    options={this.state.Currencys}
                                                    getOptionLabel={(Currency) => Currency.name || this.state.fields["currency"] || ""}
                                                    value={this.state.fields["currency"]} // Always controlled
                                                    onChange={this.handleChange.bind(this, "currency")}
                                                    id="timezone-data"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                            placeholder="Choose currency."
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} style={{ display: "block" }} id="timezone">
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Timezone
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                {this.state.timezoneAutocomplete && (
                                                    <Autocomplete
                                                        readOnly={this.state.readOnly}
                                                        options={this.state.timezoneData}
                                                        getOptionLabel={(timezone) => timezone.text || String(this.state.fields["timezoneText"])}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        value={this.state.fields["timezoneText"]}
                                                        onChange={this.handleChangeTimezone}
                                                        id="timezone-data"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                                placeholder="Choose Timezone."
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {!this.state.timezoneAutocomplete && (
                                                    <Autocomplete
                                                        readOnly={this.state.readOnly}
                                                        disableClearable
                                                        options={this.state.timezoneData}
                                                        getOptionLabel={(timezone) => timezone.text}
                                                        onChange={this.handleChangeTimezone}
                                                        id="timezone-data"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                                placeholder="Choose Timezone."
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3} style={{ display: "block" }} id="sDateGrid">
                                            <div style={{ marginBottom: 8 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Campaign Dates *
                                                </Typography>
                                            </div>
                                            <FormDateRangeCustomPicker
                                                readOnly={this.state.readOnly}
                                                sendSelectedDates={this.sendSelectedDates}
                                                parentStartDate={this.state.fields["startDate"]}
                                                parentEndDate={this.state.fields["endDate"]}
                                                needRange={false}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <div style={{ marginBottom: 0 }}>
                                                <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                    Account Manager
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={this.state.aifluencerUsers}
                                                    readOnly={this.state.readOnly}
                                                    getOptionLabel={(cname) => cname.name}
                                                    value={this.state.fields.accountManager || null} // Ensure value is not undefined
                                                    onChange={(event, value) => this.handleAccountManagerChange(value, "accountManager")}
                                                    refs="accountManager"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                            placeholder="Choose an account manager for campaign"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={12}>
                                        <div style={{ marginBottom: -5, display: "flex" }}>
                                            <Typography variant="subtitle1" gutterBottom style={{ color: "#000", marginBottom: 0 }}>
                                                Keywords
                                            </Typography>
                                            <Tooltip title="Campaign keywords are used to automatically identify campaign posts published by Influencers participating in the campaign. This can be edited later as well.">
                                                <IconButton>
                                                    <InfoIcon style={{ fontSize: 20, paddingBottom: 4 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <FormControl fullWidth>
                                            <div>
                                                <TextField
                                                    sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                    placeholder={
                                                        this.state.keywords.length > 2
                                                            ? ""
                                                            : "A few enter-separated keywords to be used in the campaign publication"
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    value={this.state.inputValue}
                                                    onChange={this.handleInputChange}
                                                    onKeyDown={this.handleInputKeyDown}
                                                    onBlur={this.handleInputBlur}
                                                    fullWidth
                                                    inputProps={{ readOnly: this.state.readOnly }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {this.state.keywords.map((keyword, index) => (
                                                                    <Chip
                                                                        key={index}
                                                                        label={keyword}
                                                                        onDelete={this.handleChipDelete(keyword)}
                                                                        style={{ marginRight: 5, marginBottom: 3 }}
                                                                    />
                                                                ))}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Divider />


                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" sx={{ mb: 1 }}>
                                                <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                                                Campaign Type *
                                            </Typography>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    readOnly={this.state.readOnly}
                                                    options={this.state.campaignType}
                                                    getOptionLabel={(campaign) => campaign || this.state.fields["campaignType"]}
                                                    value={this.state.fields["campaignType"]}
                                                    onChange={this.handleChange.bind(this, "campaignType")}
                                                    id="campaign-Type"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 7, bottom: 7 }, width: "100%" }}
                                                            placeholder="Choose campaign type."
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </MyPaper>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "right" }}>
                                <div style={{ color: "red" }}>{this.state.disableError}</div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box sx={{ mt: 4 }}>
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="button"
                                            onClick={this.postCampaignCancel.bind(this)}
                                            style={{ marginLeft: "30px" }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                    <div>
                                        <Box sx={{ mt: 4 }}>
                                            {(this.props.type === "AddCampaign" || this.state.fields.current_status === 2) && (
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    type="button"
                                                    onClick={this.postCampaignDraftSave.bind(this)}
                                                    style={{ marginRight: "30px" }}
                                                    disabled={this.state.nameDraftButtonDisable || this.state.descDraftButtonDisable}
                                                >
                                                    Save as Draft
                                                </Button>
                                            )}
                                            {!this.state.readOnly && (
                                                <Tooltip title={this.getTooltipTitle()} >
                                                    <span>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.postCampaignSave.bind(this)}
                                                            disabled={this.state.SaveButtonDisable || this.state.nameSaveButtonDisable || this.state.descSaveButtonDisable}
                                                        >
                                                            Save
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </div>
                                </div>
                            </Grid>

                        </Grid >
                    </form >

                    {/*  modal for cancle the campaign  */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.cancleModal}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={this.state.cancleModal}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    bgcolor: "background.paper",
                                    // border: '3px solid #000',
                                    borderRadius: "15px",
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography>
                                    <div style={{ marginBottom: 10 }}>
                                        This action will result in the loss of the data you have entered.
                                    </div>

                                    <div>Do you want to discard and exit?</div>
                                </Typography>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
                                    <Button variant="outlined" size="medium" type="button" onClick={closeModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" size="medium" type="button" onClick={cancelCampaign}>
                                        Yes, Discard
                                    </Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>

                    {/*  modal for save and save as draft  */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.saveModal}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={this.state.saveModal}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "auto",
                                    minWidth: 400,
                                    bgcolor: "background.paper",
                                    // border: '3px solid #000',
                                    borderRadius: "15px",
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography>
                                    {this.props.type === "AddCampaign" ? (
                                        <>
                                            <div style={{ marginBottom: 10 }}>
                                                <div><b>Campaign Name :</b> {this.state.fields["name"] || ""}</div>
                                                <div><b>Account Name :</b> {this.state.fields["companyName"] || ""}</div>
                                                {this.state.fieldsFilled["brandId"] === true ? <div><b>Brand Name :</b> {this.state.fields["brandName"] || ""}</div> : ("")}
                                                {this.state.fieldsFilled["description"] === true ? <div><b>Objective of the Campaign :</b> {this.state.fields["description"] || ""}</div> : ("")}
                                                {this.state.fieldsFilled["budget"] === true ? <div><b>Budget :</b> {this.state.fields["budget"] || ""}</div> : ("")}
                                                {this.state.fieldsFilled["currency"] === true ? <div><b>Currency :</b> {this.state.currencyname || ""}</div> : ("")}
                                                {this.state.fieldsFilled["timezone"] === true ? <div><b>Timezone :</b> {this.state.fields["timezoneText"] || ""}</div> : ("")}
                                                <div><b>Start Date :</b> {this.state.fields["startDate"] ? utils.convertDate(this.state.fields["startDate"], "normal").split(' ')[0] : ""}</div>
                                                {this.state.fieldsFilled["endDate"] === true ? <div><b>End date :</b> {this.state.fields["endDate"] ? utils.convertDate(this.state.fields["endDate"], "normal").split(' ')[0] : ""}</div> : ("")}
                                                {this.state.fieldsFilled["accountManager"] === true ? <div><b>Account Manager :</b> {this.state.fields["accountManager"]?.name || ""}</div> : ("")}
                                                {this.state.fieldsFilled["keywords"] === true ? <div><b>Keyword :</b> {this.state.fields["keywords"]?.join(", ") || ""}</div> : ("")}
                                                {this.state.fieldsFilled["campaignType"] === true ? <div><b>Campaign Type :</b> {this.state.fields["campaignType"] || ""}</div> : ("")}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginBottom: 10 }}>
                                                {this.state.fieldsChanged["name"] === true ? <div><b>Campaign Name :</b> {this.state.fields["name"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["companyId"] === true ? <div><b>Account Name :</b> {this.state.fields["companyName"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["brandId"] === true ? <div><b>Brand Name :</b> {this.state.fields["brandName"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["description"] === true ? <div><b>Objective of the Campaign :</b> {this.state.fields["description"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["budget"] === true ? <div><b>Budget :</b> {this.state.fields["budget"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["currency"] === true ? <div><b>Currency :</b> {this.state.currencyname || ""}</div> : ("")}
                                                {this.state.fieldsChanged["timezone"] === true ? <div><b>Timezone :</b> {this.state.fields["timezoneText"] || ""}</div> : ("")}
                                                {this.state.fieldsChanged["startDate"] === true ? <div><b>Start Date :</b> {this.state.fields["startDate"] ? utils.convertDate(this.state.fields["startDate"], "normal").split(' ')[0] : ""}</div> : ("")}
                                                {this.state.fieldsChanged["endDate"] === true ? <div><b>End date :</b> {this.state.fields["endDate"] ? utils.convertDate(this.state.fields["endDate"], "normal").split(' ')[0] : ""}</div> : ("")}
                                                {this.state.fieldsChanged["accountManager"] === true ? <div><b>Account Manager :</b> {this.state.fields["accountManager"]?.name || ""}</div> : ("")}
                                                {this.state.fieldsChanged["keywords"] === true ? (
                                                    <div><b>Keyword :</b>
                                                        {this.state.fields["keywords"] ? (
                                                            typeof this.state.fields["keywords"] !== "string" ? (
                                                                this.state.fields["keywords"].join(", ")
                                                            ) : (
                                                                this.state.fields["keywords"]
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) :
                                                    ("")}
                                                {this.state.fieldsChanged["campaignType"] === true ? <div><b>Campaign Type :</b> {this.state.fields["campaignType"] || ""}</div> : ("")}
                                            </div>
                                        </>
                                    )}

                                    <div>Do you want to save the Campaign?</div>
                                </Typography>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
                                    <Button variant="outlined" size="medium" type="button" onClick={closeSaveModal}>
                                        Cancel
                                    </Button>
                                    {(this.state.type === "save") ? (
                                        <Button variant="contained" size="medium" type="button" onClick={saveCampaign}>
                                            Yes, Save
                                        </Button>
                                    ) : (
                                        <Button variant="contained" size="medium" type="button" onClick={saveDraftCampaign}>
                                            Yes, Save Draft
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </Fade>
                    </Modal>

                    {/* modal for add brand  */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.addBrandModal}
                        // closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={this.state.addBrandModal}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 800,
                                    // minWidth: 400,
                                    bgcolor: "background.paper",
                                    borderRadius: "15px",
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >

                                <div id="modal-add_user-heading" className="styles_modal_heading">
                                    <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                                        Add Brand
                                    </h2>
                                    <IconButton onClick={handleCloseModal}>
                                        <Close />
                                    </IconButton>
                                </div>

                                <AddBrand
                                    accountName={this.state.fields["companyName"] ? this.state.fields["companyName"] : ""}
                                    accountId={this.state.fields["companyId"] ? this.state.fields["companyId"] : undefined}
                                    type={"Campaign"}
                                    saveTheAddedBrand={this.saveTheAddedBrand}
                                />


                            </Box>
                        </Fade>
                    </Modal>
                </>
            )
        }

    }
}
export default CampaignForm;
